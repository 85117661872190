/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import Layout from "../components/Layout";

export default () => {
	return (
		<Layout>
			<section
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					maxWidth: "40rem",
					margin: [null, "0 auto", "0 auto"],
					pb: 5,
				}}
			>
				<h1
					sx={{
						my: 3,
						fontSize: [4, 5, 5],
						textAlign: "center",
						fontWeight: "lightbold",
					}}
				>
					Vielen Dank
				</h1>
				<p
					sx={{
						px: [2, "0px", "0px"],
						fontSize: [2, 4, 4],
						fontWeight: "semibold",
						textAlign: "center",
						mt: "0px",
						mb: 2,
					}}
				>
					Wir werden uns bald zur Terminvereinbarung mit Ihnen in
					Verbindung setzen.
				</p>

				<Link
					to="/"
					sx={{
						mt: 1,
						mx: "0px",
						fontSize: [2, 3, 3],
						bg: "primary",
						color: "background",
						textDecoration: "none",
						borderRadius: "100px",
						borderStyle: "none",
						height: [1, 2, 2],
						width: [5, 7, 7],
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<span>Startseite</span>
				</Link>
			</section>
		</Layout>
	);
};
